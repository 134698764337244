<script setup>
import { ref, watch } from 'vue'
const { data, status, signIn, signOut } = useAuth()
const route = useRoute()

// if (status.value === 'unauthenticated') {
//   signOut({ callbackUrl: '/auth/login' })
// }

// watch(
//   () => status.value,
//   () => {
//     if (status.value === 'unauthenticated') {
//       signOut({ callbackUrl: '/auth/login' })
//     }
//   }, { deep: true }
// )

</script>

<template>
  <div class="grid-state-template">
    <Header />
    <el-main>
      <slot />
    </el-main>
    <div v-if="route.fullPath.includes('trip/')">
      <FooterTrip />
    </div>
    <div v-else>
      <Footer />
    </div>
  </div>
</template>
