<script setup>
import { ref, computed } from 'vue'
import { useTripStore } from '@/store/trip'

// ICONS
import HousingIcon from '@/assets/images/icons/booking/housing'
import TransportIcon from '@/assets/images/icons/booking/transport'
import OptionsIcon from '@/assets/images/icons/booking/options'
import PaymentIcon from '@/assets/images/icons/booking/payment'

const tripStore = useTripStore()
const route = useRoute()

const active = ref(+tripStore.step?.step_processed - 1)
const totalCost = ref(0)

watch(
  () => tripStore,
  async () => {
    active.value = (+tripStore.step?.step_processed - 1)
  }, { deep: true }
)
</script>

<template>
  <el-footer class="trip-footer">
    <div class="container">
      <div class="footer-box">
        <el-steps :active="active" finish-status="success">
          <el-step :class="{'activeStep': active == 1}" :title="$t('step.krok_1_z_4')" :icon="HousingIcon" />
          <el-step :class="{'activeStep': active == 2}" :title="$t('step.krok_2_z_4')" :icon="TransportIcon" />
          <el-step :class="{'activeStep': active == 3}" :title="$t('step.krok_3_z_4')" :icon="OptionsIcon" />
          <el-step :class="{'activeStep': active == 4}" :title="$t('step.krok_4_z_4')" :icon="PaymentIcon" />
        </el-steps>
        <div class="trip-cost">
          <p>{{ $t('all.cena') }}</p>
          <div class="trip-cost__nor">
            {{ tripStore.calcCost }} PLN
          </div>
        </div>
      </div>
    </div>
  </el-footer>
</template>
